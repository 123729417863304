
// Packages
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';
import withReactContent from 'sweetalert2-react-content';
import { Form } from 'react-bootstrap';
import { Redirect, NavLink, useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Text, Title, Card } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";

// Services
import ActionTypes from 'shared/utilities/action-types';

// Utilities
import { getIdByReference } from 'shared/utilities/reference-handling';
import { validateAccessCode } from 'authentication/helpers';
import { getUrlParameter } from 'shared/helpers';
import useMetaLinks from 'shared/hooks/use-meta-links';
import { ALERT_TYPE } from 'shared/utilities/modal-alert';
import { useRegisterUser } from 'authentication/hooks/use-register-user';
import { useModalContext } from 'modal-context/modal-context';
import { UseShowDecisionAlert } from 'flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert';

// Components
import AuthImage from 'authentication/components/auth-image';
import CustomButton from 'shared/components/custom-button';
import CustomFormGroup from 'shared/components/custom-form-group';
import Helmet from 'shared/components/helmet';
import AuthHeader from 'authentication/components/auth-header';
import SsoForm from 'authentication/components/sso-form/sso-form';

// Scss
import 'scss/base.scss';

function formIsValid(input) {
  return isEmail(input) || validateAccessCode(input);
}
export default function RegisterGetDataPage(props) {
  const [redirect, setRedirect] = useState('');
  const { t, i18n } = useTranslation([
    'translations',
    'messageTranslations',
    'helmetTranslations',
    'placeholderTranslations',
  ]);
  const dispatch = useDispatch();
  const [input, setInput] = useState('');
  const { loading } = useSelector((state) => state.auth, shallowEqual);
  const MySwal = withReactContent(Swal);
  const { reference } = useSelector((state) => state.auth, shallowEqual);
  const history = useHistory();
  const { register } = useRegisterUser();
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();

  const { support } = useMetaLinks();

  const onSwalClick = (path) => {
    MySwal.close();
    history.push(`/${path}`, { mail: input });
  };

  const handleForgotPassword = () => {
    onSwalClick('password');
    close();
  }

  const handleSignIn = () => {
    onSwalClick('');
    close();
  }
  const showAccoutExistsModal = () => {
    show({
      title: <Title size="h2">{t('messageTranslations:This login already exists')}</Title>,
      content: <div className="d-flex flex-column">
        <Text>
          {t(
            'messageTranslations:Would you like to log in to your account or change you password?'
          )}
        </Text>
        <hr className="my-3" aria-hidden="true" />
      </div>,
      footer: <>
        <Button
          variant="secondary"
          onClick={() => handleForgotPassword()}
          aria-label={t('Forgot password?')}
        >
          {t('Forgot password?')}
        </Button>
        <span className="mx-1" aria-hidden="true" />
        <Button
          variant="primary"
          onClick={() => handleSignIn('')}
          aria-label={t('Sign in now')}
        >
          {t('Sign in now')}
        </Button>
      </>,
      type: ALERT_TYPE.INFO,
    })
  };

  const location = useLocation();

  useEffect(() => {
    const registerKey = getUrlParameter(location.search, 'register');
    if (registerKey) {
      register({ key: registerKey });
    }
  }, []);

  useEffect(() => {
    switch (getIdByReference(reference)) {
      case 'REGISTRATION_DATA_SUCCESS_CODE':
      case 'REGISTRATION_DATA_SUCCESS_EMAIL':
      case 'REGISTRATION_SSO_LOGIN':
        setRedirect('/register/conf');
        break;
      case 'REGISTRATION_ACCOUNT_EXISTS': {
        showAccoutExistsModal();
        dispatch({ type: ActionTypes.CLEAR_REFERENCE });
        break;
      }
      case 'REGISTRATION_DATA_FAILED_EXISTS': {
        showAccoutExistsModal();
        dispatch({ type: ActionTypes.CLEAR_REFERENCE });
        break;
      }
      default:
        break;
    }
  }, [reference]);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let data = {};
    if (isEmail(input)) {
      data = { email: input };
    } else if (validateAccessCode(input)) {
      data = { code: input };
    }
    register(data);
  };

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Registration')} />
      <AuthHeader />
      <main className="m-auto auth-card-width">
        <div>
          <Card shadow="sm" className="auth-card">
            <div className="d-flex flex-row">
              <AuthImage flipx={i18n.language === 'ar'} />
              <div className="base-card-right flex-grow-1">
                <h2 className="mb-2 h1 text-center register-login-title-weight">{t('Register')}</h2>
                <hr />
                <Form onSubmit={onSubmit} className="u-flex u-flexCol">
                  <CustomFormGroup
                    id="input"
                    label={t('Business email address or access code')}
                    isTrimOnBlur
                    placeholder={t('placeholderTranslations:Email or code')}
                    value={input}
                    handleOnChange={(value) => setInput(value.input?.trim())}
                    valid={formIsValid(input)}
                    autoComplete="email"
                    aria-label={t('Business email address or access code')}
                  />
                  <Button onClick={onSubmit} disabled={!formIsValid(input)}>
                    {loading ? t('Fetching data...') : t('Next')}
                  </Button>
                </Form>
                <SsoForm type="REGISTER" doSignUp={props.doSignUp} py="lg" />
                <p className="text-center">
                  {t('Already registered?')}
                  <Link className="text-nowrap ml-2" to="/">
                    {t('Go to login')}.
                  </Link>
                </p>

                {support && (
                  <React.Fragment>
                    <hr />
                    <p className="text-center mb-1">
                      <a href={support} aria-label={t('Go to support')} target="_blank" rel="noopener noreferrer">
                        {t('Go to support')}
                      </a>
                    </p>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Card>
        </div>
      </main>
    </React.Fragment>
  );
}

RegisterGetDataPage.propTypes = {
  doSignUp: PropTypes.func.isRequired,
};
