import React, { ReactElement } from 'react';
import { Button } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { useTranslation } from 'react-i18next';
import { IconBrandWindows } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons';
import { SsoFlow } from './sso-form';

interface GoogleSsoButtonProps {
  type: SsoFlow;
  onClick: () => void;
}
const MicrosoftSsoButton: React.FC<GoogleSsoButtonProps> = ({
  type,
  onClick,
}): ReactElement => {
  const { t } = useTranslation('translations');

  return (
    <Button
      size="sm"
      justify="space-between"
      variant="secondary"
      leftSection={<IconBrandWindows size={14} fontWeight={200} />}
      rightSection={<span />}
      onClick={onClick}
      aria-label={
        type === 'LOGIN'
          ? t('Login with Microsoft')
          : t('Registration with Microsoft')
      }
    >
      {type === 'LOGIN'
        ? t('Login with Microsoft')
        : t('Registration with Microsoft')}
    </Button>
  );
};

export default MicrosoftSsoButton;
