import React, { ReactElement } from 'react';
import {
  Stack,
  StackProps,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { useHistory } from 'react-router';
import { UseGlobalSettingsContext } from 'shared/modules/global-settings/state/global-settings-context.provider';
import { AuthFunction } from 'authentication/types/auth-funtion.type';

import GoogleSsoButton from './google-sso-btn.component';
import SamlSsoButton from './saml-sso-button.component';
import MicrosoftSsoButton from './microsoft-sso-btn.component';
import useHasAuthMode from './use-has-auth-mode.hook';

export enum SsoFlow {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
}

interface SsoFormProps extends StackProps {
  doSignIn?: AuthFunction;
  doSignUp?: AuthFunction;
  flowType: SsoFlow.LOGIN | SsoFlow.REGISTER;
}

export default function SsoForm({
  doSignIn,
  doSignUp,
  flowType = SsoFlow.LOGIN,
  ...stackProps
}: SsoFormProps): ReactElement {
  const history = useHistory();
  const { globalSettings } = UseGlobalSettingsContext();

  const { hasSamlSso, hasMicrosoftSso, hasGoogleSso } = useHasAuthMode();

  const onMicrosoftSso = () => {
    if (flowType === SsoFlow.LOGIN) {
      doSignIn?.('MICROSOFT');
    }
    if (flowType === SsoFlow.REGISTER) {
      doSignUp?.('MICROSOFT');
    }
  };

  const onSamlSso = () => {
    if (!globalSettings.domain) {
      history.push('/saml');
      return;
    }
    if (flowType === SsoFlow.LOGIN) {
      doSignIn?.('SAML', {
        domain: globalSettings.domain,
      });
    }
    if (flowType === SsoFlow.REGISTER) {
      doSignUp?.('SAML', {
        domain: globalSettings.domain,
      });
    }
  };

  return (
    <Stack {...stackProps}>
      {hasMicrosoftSso && (
        <MicrosoftSsoButton type={flowType} onClick={onMicrosoftSso} />
      )}
      {hasSamlSso && <SamlSsoButton type={flowType} onClick={onSamlSso} />}
      {hasGoogleSso && <GoogleSsoButton />}
    </Stack>
  );
}
